import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Germany/Itinerary_Germany_tour.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Germany/Itinerary_1.jpeg";
import pictureTwo from "./../../img/Germany/Itinerary_2.jpeg";
import pictureThree from "./../../img/Germany/Itinerary_3.jpeg";

function ItalyItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">April 21 - May 2, 2025</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      {/* <p className="text">
        This 10-day trip to Switzerland will take you to Zurich, Lucerne, Schangnau, Lauterbrunnen, Murren, and Appenzell. Ride to the top of Mt. Pilatus and boat on Lake Lucerne. Gondolas, cogwheel trains, and cable cars will take you to vistas with mind-blowing views in the heart of the Swiss Alps. Learn about the Swiss Reformation, eat traditional Swiss food, and enjoy the fellowship of a small tour group.       </p> */}
      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="Poland town"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="Poland solt mines"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="Polamd mountains"
          />
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Monday – April 21</h2>
        <p className="text">
          Depart the USA.
        </p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Tuesday – April 22</h2>
        <p className="text">
          Arrive in Berlin, Germany. Get settled into our hotel, rest, and/or explore the surrounding area in the heart of Berlin (overnight in Berlin).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Wednesday – April 23</h2>
        <p className="text">
          On our first full day in Germany, we will tour Berlin. This will include Checkpoint Charlie, the Brandenburg Gate, and a walk past the Reichstag building which functions as the seat of the federal German parliament. As time permits, we will visit other sites in the city (overnight in Berlin).



          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button> */}
        </p>


        {/*Box 3*/}
        {/* <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            
          </div>
        </div> */}
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Thursday – April 24</h2>
        <p className="text">
          In the morning, we will tour the house of the Bonhoeffer family. This is the house where Dietrich Bonhoeffer was arrested by the Gestapo and from where he was taken to prison. In the afternoon, we will drive through the beautiful German countryside to the Moravian town of Herrnhut (overnight in Herrnhut).



          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 4*/}
      {/* <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          
        </div>
      </div> */}
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Friday – April 25</h2>
        <p className="text">
          In Herrnhut we will have a local guide who will give us a tour of the important sites connected to the estate of Count Zinzendorf who hosted the Moravians on his property. We will learn how the Moravians experienced a “Pentecost” in 1727 which led to a 100-year prayer meeting and the “dawn of Protestant missions,” as well as greatly influencing many people including John Wesley and William Carey (overnight in Herrnhut).


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 5*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            Travel to Thessalonica: Thessaloniki (pop. 814,000) is called the
            second capital by the Greeks, since it is not only the second
            largest city of Greece after Athens, but also the capital of
            Northern Greece and Macedonia. It is beautifully situated by the
            sea.
          </p>
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a
            church, stroll along the harbour, take a boat ride in the
            harbor/gulf (Aegean Sea), and view the statute of Alexander the
            Great
          </p>
        </div>
      </div> */}
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Saturday – April 26</h2>
        <p className="text">
          We will depart Herrnhut in the morning and drive to Wittenberg. On the way, we will make a stop in Dresden or Leipzig for lunch and visit St. Thomas Church, a Lutheran church in the center of town. It is most famous as the place where Johann Sebastian Bach worked as a
          music minister and as the current location of his remains. Leipzig is also the scene of the celebrated Leipzig disputation of 1519 when Martin Luther and Andreas Karlstadt debated Johann Eck on such topics as papal primacy and the infallibility of church councils (overnight in Wittenberg).


          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 6*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Leaving Thessalonika we will travel east to Philippi: After settling
            into our lodgings, we will travel eight miles to the coast and eat
            at the harbour in Kavala (biblical Neapolis), the one Paul sailed
            into from Troas in Turkey/Asia Minor after he received the
            “Macedonian vision” (Acts 16:6-12).
          </p>
        </div>
      </div> */}
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Sunday – April 27</h2>
        <p className="text">
          On this day we will tour Wittenberg. This sleepy little town in rural eastern Germany doesn't look like the center of a revolution, but the events that played out here in the 16th Century shook the foundations of Christendom and began the Protestant Reformation. It was here in Wittenberg, Germany, that Martin Luther lived and preached, and on October 31, 1517, he nailed his 95 Theses to the door of the Castle Church where Luther is buried. In addition to visiting the
          Castle Church, we will also pay a visit to the Town Church (where Luther did most of his preaching in Wittenburg), Luther’s Residence, and the Oak Tree planted where Luther burned the Papal Bull (overnight in Wittenberg).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 7*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div> */}
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Monday – April 28</h2>
        <p className="text">
          Leaving Wittenberg, we will drive to Eisleben, the place where Luther was born and where he died. Our visit will include stops at the house where he was born, the church where he was baptized, the church where he preached his last sermon, and the house where he died. And then, driving towards Erfurt, we will stop in the field where the 22-year-old Luther was walking when he fell to the ground in a thunderstorm and promised to become a monk. This experience changed everything in his life and would eventually lead to a Protestant Reformation. Later in the afternoon, we will drive to Eisenach (overnight in Eisenach).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 9*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          
        </div>
      </div> */}

      {/* day 10 */}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Tuesday – April 29</h2>
        <p className="text">
          In the morning, we will stroll up the hill from the town of Eisenach to the Castle of Wartburg and see the Luther Room where Martin Luther was imprisoned for approximately 10 months, and where he translated the Bible into German. In the afternoon, we will drive to the small eastern German agricultural community of Waldthurn (overnight in Waldthurn).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/* Day 11 */}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Wednesday – April 30</h2>
        <p className="text">
          After a brief tour of the area around Waldthurn, we will drive the short distance to Flossenburg, the concentration camp where Dietrich Bonhoeffer was executed by the Nazis. In the afternoon, we will drive to Munich and if we have time, we will stop for a brief visit (and a late lunch or early dinner) in the center of the city. In the evening, we will drive into the Bavarian Alps and arrive at the charming town of Mittenwald with its marvelous views of the surrounding mountains (overnight in Mittenwald).

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>

      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Thursday – May 1</h2>
        <p className="text">
          This will be a relaxing day in Mittenwald and the surrounding area. Mittenwald is famous since the 17th century for its violin-making and paintings on the sides of its buildings. Hiking, eating, and relaxing will be the order of the day.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      <div>
        <h2 className={styles.dayNumber}>Day 12, Friday – May 2</h2>
        <p className="text">
          After driving to the Munich airport, we will depart Germany for home.

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>


    </div>
  );
}

export default ItalyItinerary;
