import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
// import picture_2 from "../../img/Greece/Day 2 Athens.jpeg";
// import picture_3 from "../../img/Greece/Day 3 Athens.jpeg";
// import picture_4 from "../../img/Greece/Day 4 Mt. Olympas.jpeg";
// import picture_5 from "../../img/Greece/Day 5 Thessalonika.jpeg";
// import picture_6 from "../../img/Greece/Day 6 Kavala.jpeg";
// import picture_7 from "../../img/Greece/Day 7 Philippi.jpeg";
// import picture_8 from "../../img/Greece/Day 8 Berea.jpeg";
// import picture_9 from "../../img/Greece/Day 9 Meteora.jpeg";
// import picture_10 from "../../img/Greece/Day 10 Actium.jpeg";
// import picture_11 from "../../img/Greece/Day 11 Corinth.jpeg";
import itinerary from "../../img/Turkey/Itinerary_Turkey.pdf";
import { IoClose } from "react-icons/io5";
import pictureOne from "./../../img/Turkey/Itinerary_1_Patmos.jpg";
import pictureTwo from "./../../img/Turkey/Itinerary_2_Top_of_Pergamos.jpg";
import pictureThree from "./../../img/Turkey/Itinerary_3_Istanbul.jpg";

function TurkeyItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Itinerary</p>
      <p className="secondaryTitle">Tour of Turkey</p>
      <p className="secondaryTitle">September 22 - October 3, 2024
      </p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <div className="pictureRow">
        <div>
          <img
            className="picture"
            src={pictureOne}
            alt="Poland town"
          />
        </div>
        <div className="hide">
          <img
            className="picture"
            src={pictureTwo}
            alt="Poland solt mines"
          />
        </div>
        <div>
          <img
            className="picture"
            src={pictureThree}
            alt="Polamd mountains"
          />
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Sunday - Sept 22</h2>
        <p className="text">
          Depart USA in the PM

        </p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Monday - Sept 23</h2>
        <p className="text">
          Arrive Istanbul and get to hotel. (overnight in Istanbul)
          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Tuesday - Sept 24</h2>
        <p className="text">
          Tour Istanbul: Hagios Sofia, cruise on the Bosphorus, and more. (overnight in Istanbul)
          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button> */}
        </p>
        {/*Box 3*/}
        {/* <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Athens: we will stroll through the Ancient Agora (Marketplace)
              visiting the Stoa of Attalos, a stoa lined with shops built in the
              2nd century B.C. which has since been reconstructed for use as the
              Museum of the Ancient Agora. Here we visit many other ancient
              ruins/buildings of ancient Athens. This is where philosophers like
              Socrates gave advice to “know thyself,” and Zeno the Stoic
              lectured on how to control one&apos;s emotions. This is also
              considered to be the seat of western democracy.
            </p>
            
          </div>
        </div> */}
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Wednesday - Sept 25</h2>
        <p className="text">
          Fly to Izmir and transfer to see Smyrna Agora and new Museum. (overnight in Kusadasi) (B,D)
          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 4*/}
      {/* <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            On this day, we will drive to base of the highest mountain in
            Greece, Mt. Olympus, making stops at Marathon, and Thermopylae
            (scene of the famous 300 Spartans).
          </p>
          <p className="text">
            Marathon is a small town which was the site of the Battle of
            Marathon (490 BCE), in which the heavily outnumbered Athenian army
            defeated the Persians. It’s believed that Pheidippides, a Greek
            herald at the battle, was sent running from Marathon to Athens to
            announce the victory, which is how the marathon running race was
            conceived in modern times. But there is a lot more to this story and
            you will learn it.
          </p>
          
        </div>
      </div> */}
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Thursday - Sept 26</h2>
        <p className="text">
          Ephesus (overnight Kusadasi) (B,D)
          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 5*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            Travel to Thessalonica: Thessaloniki (pop. 814,000) is called the
            second capital by the Greeks, since it is not only the second
            largest city of Greece after Athens, but also the capital of
            Northern Greece and Macedonia. It is beautifully situated by the
            sea.
          </p>
          <p className="text">
            We will visit the ruins of the ancient city where Paul founded a
            church, stroll along the harbour, take a boat ride in the
            harbor/gulf (Aegean Sea), and view the statute of Alexander the
            Great
          </p>
        </div>
      </div> */}
      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Friday - Sept 27</h2>
        <p className="text">
          A day boat trip to the Island of Patmos: Cave of the Apocalypse (overnight Kusadasi) (B,D)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 6*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Leaving Thessalonika we will travel east to Philippi: After settling
            into our lodgings, we will travel eight miles to the coast and eat
            at the harbour in Kavala (biblical Neapolis), the one Paul sailed
            into from Troas in Turkey/Asia Minor after he received the
            “Macedonian vision” (Acts 16:6-12).
          </p>
        </div>
      </div> */}
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Saturday - Sept 28</h2>
        <p className="text">
          Visit Priene, Miletus, Didyma (overnight Izmir) (B,D)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 7*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Tour the archaeological site of ancient Philippi where Paul and
            Silas were imprisoned for casting a spirit of divination out of a
            young slave girl and where they baptized their first European
            convert, Lydia, by the stream beside the town. We will have a Bible
            study from Philippians on the top of the acropolis of Philippi where
            we will enjoy a gorgeous view of the Macedonian mountains and plain
            below the acropolis where the great Roman civil war battle took
            place (42 BC). Here the forces of Octavius (Caesar Augustus) and
            Mark Anthony defeated the legions loyal to Brutus and Cassius, Roman
            senators, who were instigators in the murder of Julius Caesar two
            years earlier. This battle changed the ancient world forever as Rome
            was transformed into empire from a republic.
          </p>
        </div>
      </div> */}
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Sunday - Sept 29</h2>
        <p className="text">
          Pergamum with Acropolis and Asklepion; Thyatira (overnight Salihli) (B,D)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 8*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            This will be a travel day to from Philippi to Meteora in the
            mountains of central Greece with a stop in Berea for lunch. It is
            believed that Paul was in the area where we will visit an ancient
            synagogue.
          </p>
          
        </div>
      </div> */}
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Monday - Sept 30</h2>
        <p className="text">
          Sardis, Philadelphia, Hierapolis (overnight Pamukkale) (B,D)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 9*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_9}
            alt="Meteora Monastery"
          />
          <p className="text">
            Meteora: Our visit here will take in at least two of the stunning
            monasteries built on the cliffs of central Greece. There are six
            Meteora monasteries perched on the huge cliffs people can visit
            today. Serene, mystical, extraordinary, breathtaking, immense,
            inspiring, impressive. These are only some of the words people very
            often use in an effort to describe the Meteora monasteries.
          </p>
        </div>
      </div> */}
      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Tuesday - Oct 1</h2>
        <p className="text">
          Laodicea and Colossae; drive to Antalya (overnight Antalya)(B,D)

          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 10*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            This day we will drive to Corinth with a stop and lunch at the
            strait of Actium where Octavian gained his celebrated victory over
            the forces Mark Antony and Cleopatra in 31 BC. This naval battle
            resulted in Octavias (Augustus Caesar) being the sole emperor of the
            Roman Empire. This same man would later give the degree that moved
            Joseph and Mary to Bethlehem where Jesus Christ was born fulfilling
            the prophecy of Micah the prophet. We will also visit the camp of
            Octavian, and where he later set up a memorial to himself for
            winning that historic battle
          </p>
        </div>
      </div> */}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Wednesday - Oct 2</h2>
        <p className="text">
          Magydos waterfalls, Perga, lunch at St. Paul Cultural Center; Walking tour of Attalia and new Necropolis Museum (overnight Antalya) (B,D)

        </p>
      </div>
      <div>
        <h2 className={styles.dayNumber}>Day 12, Thursday - Oct 3</h2>
        <p className="text">
          Fly to Istanbul in the AM and to the USA in the PM

        </p>
      </div>

    </div>
  );
}

export default TurkeyItinerary;
